/**
 * @generated SignedSource<<932ef7bb7843011e1516710fc98f3afe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type aiArticleListSponsoredArticle$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
  readonly " $fragmentType": "aiArticleListSponsoredArticle";
};
export type aiArticleListSponsoredArticle$key = {
  readonly " $data"?: aiArticleListSponsoredArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"aiArticleListSponsoredArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "aiArticleListSponsoredArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeSecondaryContentItemContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "0f982a7fa7a5eae92cccd92799de8bb9";

export default node;
