/**
 * @generated SignedSource<<f5725e84812dd568e029e8ddfc2e5534>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type chinaFutureTechStoryPackageQuery$data = {
  readonly chinaFutureTechStoryPackageArticles: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
      }>;
    } | null | undefined;
    readonly settings: unknown | null | undefined;
  } | null | undefined;
  readonly section: {
    readonly __typename: "Section";
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
        }>;
      } | null | undefined;
      readonly settings: unknown | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase" | "sectionTermLogoSection">;
  };
  readonly " $fragmentType": "chinaFutureTechStoryPackageQuery";
};
export type chinaFutureTechStoryPackageQuery$key = {
  readonly " $data"?: chinaFutureTechStoryPackageQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"chinaFutureTechStoryPackageQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settings",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "queueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sectionEntityId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "chinaFutureTechStoryPackageQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "sectionEntityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "followButtonBase"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "sectionTermLogoSection"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QueueItemsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "entityOnelineMenuQueueItemsEdge"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "chinaFutureTechStoryPackageArticles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "queueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesBlockQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "bca538222f017cfd63f270ec5ce0d91e";

export default node;
