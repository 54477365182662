import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeSuperSplashLargePrimaryContentItemContent$key } from "scmp-app/queries/__generated__/homeSuperSplashLargePrimaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  CoverImage,
  CoverImageStyledEntityLink,
  Headline,
  HeadlineContainer,
  LiveTagContainer,
  StyledEntityLink,
  SubContentInDesktop,
  SubHeadline,
} from "./styles";

export type Props = ContentItemRenderVariantProps & {
  children?: ReactNode;
  reference: homeSuperSplashLargePrimaryContentItemContent$key;
};

const Component = forwardRef<HTMLDivElement, Props>(
  ({ children, className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeSuperSplashLargePrimaryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageSize540x360: true
              withCoverImageSize1200x800: true
              withHeadline: true
              withLiveTag: true
              withSubHeadline: true
              withActionBar: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <CoverImageStyledEntityLink onClick={() => onClick?.(content.entityId)} reference={content}>
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
              withCaption: false,
            })}
          </CoverImage>
        </CoverImageStyledEntityLink>
        <Content>
          <StyledEntityLink onClick={() => onClick?.(content.entityId)} reference={content}>
            <LinkHighlightArea>
              <HeadlineContainer>
                <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>
                <Headline>
                  {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
                </Headline>
              </HeadlineContainer>
              <SubHeadline>{providers.subHeadline()}</SubHeadline>
            </LinkHighlightArea>
          </StyledEntityLink>
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: true,
            })}
          </ActionBar>
          <SubContentInDesktop>{children}</SubContentInDesktop>
        </Content>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeSuperSplashLargePrimary";

export const ContentItemHomeSuperSplashLargePrimary = withHighlightedHeadline(Component);
