import styled from "@emotion/styled";
import { fontMerriweather, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
    align-items: flex-start;

    column-gap: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;
  }
`;

export const ArticleNode = styled.div`
  display: flex;
  flex-direction: row;

  inline-size: 100%;
  padding-block: 20px;

  column-gap: 12px;

  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.up("tablet")} {
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;

    inline-size: 20%;
    padding-block: 0;
    border-block-end: 0;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;

    inline-size: 100%;
    padding-block: 20px;

    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const SerialNumberAndMarigoldDot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SerialNumber = styled.div`
  position: relative;

  font-weight: 300;
  font-size: 28px;
  font-family: ${fontMerriweather};
  font-style: normal;
  line-height: 90%;
`;

export const MarigoldDot = styled.div`
  position: relative;
  z-index: -1;

  inline-size: 20px;
  block-size: 20px;
  margin-block-start: -12px;

  border-radius: 50%;

  background-color: #ffca05;
`;
