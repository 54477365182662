import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import type { superSplashLiveLiveWidget$key } from "scmp-app/queries/__generated__/superSplashLiveLiveWidget.graphql";

import {
  Container,
  Contents,
  HomeTimelineDesktopContainer,
  HomeTimelineMobileContainer,
  Label,
  SecondaryContentContainer,
  StyledHomeTimelines,
  StyledLoading,
  StyledPrimaryContentItemRender,
  StyledSecondaryContentItemRender,
} from "./styles";

type Props = {
  className?: string;
  reference: superSplashLiveLiveWidget$key;
};

export const HomeSuperSplashLive: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment superSplashLiveLiveWidget on LiveWidget {
        label
        relatedArticles {
          entityUuid
          urlAlias
          ...homeSuperSplashLivePrimaryContentItemContent
          ...homeSuperSplashLiveSecondaryContentItemContent
        }
      }
    `,
    reference_,
  );

  const label = data?.label;
  const [firstArticle, secondArticle] = data?.relatedArticles ?? [];
  const firstArticleEntityUuid = firstArticle?.entityUuid ?? "";
  const firstArticleUrlAlias = firstArticle?.urlAlias ?? "";

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Contents>
        {firstArticle && (
          <StyledPrimaryContentItemRender reference={firstArticle}>
            <SecondaryContentContainer>
              <ClientSideSuspense>
                <HomeTimelineMobileContainer>
                  {firstArticleEntityUuid && (
                    <StyledHomeTimelines
                      entityUuid={firstArticleEntityUuid}
                      urlAlias={firstArticleUrlAlias}
                    />
                  )}
                </HomeTimelineMobileContainer>
              </ClientSideSuspense>
              {secondArticle && (
                <StyledSecondaryContentItemRender reference={secondArticle} withComment={true} />
              )}
            </SecondaryContentContainer>
          </StyledPrimaryContentItemRender>
        )}
        <HomeTimelineDesktopContainer>
          <ClientSideSuspense fallback={<StyledLoading />}>
            {firstArticleEntityUuid && (
              <StyledHomeTimelines
                entityUuid={firstArticleEntityUuid}
                urlAlias={firstArticleUrlAlias}
              />
            )}
          </ClientSideSuspense>
        </HomeTimelineDesktopContainer>
      </Contents>
    </Container>
  );
};

HomeSuperSplashLive.displayName = "HomeSuperSplashLive";
