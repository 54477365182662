import styled from "@emotion/styled";
import { fontMerriweather, theme } from "@product/scmp-sdk";

import { Loading } from "scmp-app/components/common/loading";
import { ContentItemHomeSuperSplashLivePrimary } from "scmp-app/components/content/content-item-render/variants/home-super-splash-live-primary";
import { ContentItemHomeSuperSplashLiveSecondary } from "scmp-app/components/content/content-item-render/variants/home-super-splash-live-secondary";

import { HomeTimelines } from "./timelines";

export const Container = styled.div``;

export const Contents = styled.div`
  display: flex;

  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid #000000;
  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 32px;
    padding-block-end: 32px;
  }
`;

export const Label = styled.div`
  margin-block-end: 20px;

  color: #000000;
  font-weight: 300;
  font-size: 20px;
  font-family: ${fontMerriweather};
  line-height: 25px;
  text-align: center;
  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 32px;

    font-size: 40px;
    line-height: 50px;
  }
`;

export const StyledPrimaryContentItemRender = styled(ContentItemHomeSuperSplashLivePrimary)`
  ${theme.breakpoints.up("tablet")} {
    flex: 1;

    inline-size: calc(100% - 300px);
    margin-inline-end: 32px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-inline-end: 40px;
  }
`;

export const SecondaryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSecondaryContentItemRender = styled(ContentItemHomeSuperSplashLiveSecondary)`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const StyledLoading = styled(Loading)``;

export const StyledHomeTimelines = styled(HomeTimelines)`
  border-inline-start: 1px dashed #979797;
`;

export const HomeTimelineDesktopContainer = styled.div`
  display: none;
  ${theme.breakpoints.up("tablet")} {
    display: block;

    inline-size: 300px;
    block-size: 100%;
    margin-inline-end: 38px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-inline-end: 0;
  }
`;

export const HomeTimelineMobileContainer = styled.div`
  display: block;

  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.up("tablet")} {
    display: none;
  }
`;
