import { type FunctionComponent, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";

import { HomeSuperSplashLarge } from "scmp-app/components/home/super-splash/variants/super-splash-large";
import { HomeSuperSplashLive } from "scmp-app/components/home/super-splash/variants/super-splash-live";
import type { superSplashQuery$key } from "scmp-app/queries/__generated__/superSplashQuery.graphql";

import { Container } from "./styles";

type Props = {
  className?: string;
  reference: superSplashQuery$key;
};

export const HomeSuperSplash: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment superSplashQuery on Query
      @argumentDefinitions(edition: { type: "LiveWidgetEditionEnum!" }) {
        liveWidget(
          filter: { edition: $edition }
          orderBy: { direction: DESC, field: UPDATED_DATE }
        ) {
          style
          ...superSplashLiveLiveWidget
          ...superSplashLargeLiveWidget
        }
      }
    `,
    reference_,
  );

  const style = data?.liveWidget?.style;

  const liveWidget = data?.liveWidget;

  const superSplashWidget = useMemo(() => {
    if (!liveWidget) return null;
    switch (style) {
      case "large_1":
      case "large_2":
        const labelType = style === "large_1" ? "large" : "small";
        return <HomeSuperSplashLarge labelType={labelType} reference={liveWidget} />;
      case "live":
        return <HomeSuperSplashLive reference={liveWidget} />;
    }
    return null;
  }, [liveWidget, style]);

  if (!superSplashWidget) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container className={className}>{superSplashWidget}</Container>
    </BaseLinkContextProvider>
  );
};

HomeSuperSplash.displayName = "HomeSuperSplash";
