import type { FunctionComponent } from "react";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";

import DevicesImage from "./devices.png";
import {
  Container,
  Description,
  DescriptionContainer,
  DevicesImageContainer,
  HightLightDescription,
  StyledImage,
  StyledMyNewsLink,
  TextContainer,
} from "./styles";

export const SetupCallToAction: FunctionComponent = () => (
  <Container>
    <TextContainer>
      <DescriptionContainer>
        <Description>We curate news that matters to you with our </Description>
        <HightLightDescription>Daily 5 must-read articles</HightLightDescription>
        <Description> plus a comprehensive list of stories from your favourite topics.</Description>
      </DescriptionContainer>
      <StyledMyNewsLink
        onClick={() => {
          sendGA4Tracking({
            action: "click",
            category: "mynews",
            customized_parameters: {
              action_type: "redirect",
              trigger_point: "mynews_widget",
            },
            subcategory: "mydaily",
          });
        }}
      >
        set up now
      </StyledMyNewsLink>
    </TextContainer>
    <DevicesImageContainer>
      <StyledImage src={DevicesImage.src} />
    </DevicesImageContainer>
  </Container>
);

SetupCallToAction.displayName = "SetupCallToAction";
