import { useSessionStorageValue } from "@react-hookz/web";
import { minutesToMilliseconds } from "date-fns";
import { useEffect } from "react";
import { useIdle } from "react-use";

export const useAutoRefreshWhenIdlingForTenMinutes = () => {
  const isIdle = useIdle(minutesToMilliseconds(10));

  const { setIsAutoRefreshed } = useIsAutoRefreshed();

  useEffect(() => {
    if (!isIdle) {
      return;
    }

    setIsAutoRefreshed("true");
    window.location.reload();
  }, [isIdle, setIsAutoRefreshed]);
};

export const useIsAutoRefreshed = () => {
  const {
    remove: removeIsAutoRefreshed,
    set: setIsAutoRefreshed,
    value: isAutoRefreshed,
  } = useSessionStorageValue<string>("is_auto_refreshed");

  return {
    isAutoRefreshed,
    removeIsAutoRefreshed,
    setIsAutoRefreshed,
  };
};
