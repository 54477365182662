/**
 * @generated SignedSource<<6fdd0317c4773604402e461c8506b744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type superSplashLargeLiveWidget$data = {
  readonly description: string | null | undefined;
  readonly label: string | null | undefined;
  readonly relatedArticles: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"homeSuperSplashLargePrimaryContentItemContent" | "homeSuperSplashLiveSecondaryContentItemContent">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "superSplashLargeLiveWidget";
};
export type superSplashLargeLiveWidget$key = {
  readonly " $data"?: superSplashLargeLiveWidget$data;
  readonly " $fragmentSpreads": FragmentRefs<"superSplashLargeLiveWidget">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "superSplashLargeLiveWidget",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "relatedArticles",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "homeSuperSplashLargePrimaryContentItemContent"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "homeSuperSplashLiveSecondaryContentItemContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveWidget",
  "abstractKey": null
};

(node as any).hash = "71e14477170a5b5554a41f0cbc91cf40";

export default node;
