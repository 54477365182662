import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";
import { getAbsoluteUrl } from "shared/lib/utils";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { advertisersQueueItemConnection$key } from "scmp-app/queries/__generated__/advertisersQueueItemConnection.graphql";

import {
  Container,
  Content,
  StyledArrowNext,
  StyledArrowPrevious,
  StyledContentItemHomeAdvertiser,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  SwiperWrapper,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: advertisersQueueItemConnection$key;
};

export const HomeAdvertisers: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const multimedia = useFragment(
    graphql`
      fragment advertisersQueueItemConnection on QueueItemConnection {
        edges {
          node {
            ... on Content {
              entityId
              ...homeAdvertiserContentItemContent
            }
          }
        }
      }
    `,
    reference_,
  );

  const listOfArticles = (multimedia?.edges?.map(item => item.node.entityId) ?? []).join(",");

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "advertorial_carousel",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const renderAdvertisersItems = () =>
    (multimedia.edges ?? []).map((item, index) => (
      <StyledSwiperSlide key={index}>
        <StyledContentItemHomeAdvertiser
          key={item?.node.entityId}
          onClick={(entityId, urlAlias) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "advertorial_carousel",
              },
              subcategory: "widget",
            });
          }}
          reference={item?.node}
        />
      </StyledSwiperSlide>
    ));

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.FromOurAdvertises,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>FROM OUR ADVERTISERS</Title>
        <Content>
          <SwiperContainer>
            <SwiperWrapper>
              <StyledSwiper
                breakpoints={{
                  0: {
                    spaceBetween: 32,
                  },
                }}
                freeMode={true}
                modules={[Navigation]}
                navigation={{
                  disabledClass: "card-swiper-button-disabled",
                  nextEl: ".card-swiper-button-next",
                  prevEl: ".card-swiper-button-prev",
                }}
                pagination={false}
                slidesPerGroupAuto={true}
                slidesPerView="auto"
              >
                {renderAdvertisersItems()}
              </StyledSwiper>
              <div className="swiper-button card-swiper-button-prev">
                <StyledArrowPrevious />
              </div>
              <div className="swiper-button card-swiper-button-next">
                <StyledArrowNext />
              </div>
            </SwiperWrapper>
          </SwiperContainer>
        </Content>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeAdvertisers.displayName = "HomeAdvertisers";
