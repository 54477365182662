import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import { MyNewsLink } from "scmp-app/components/home/my-news-daily-five/my-news-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-block-start: 20px;

  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;

    padding-block-start: 0;

    column-gap: 20px;

    border-block-end: 0;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;

    padding-block-start: 20px;

    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  ${theme.breakpoints.up("tablet")} {
    padding-block: 0;
  }
`;

export const DescriptionContainer = styled.div``;

export const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 140%;
`;

export const HightLightDescription = styled(Description)`
  font-weight: 700;
  /** prevent the word with hyphen not to be separated into 2 lines */
  white-space: nowrap;
`;

export const StyledMyNewsLink = styled(MyNewsLink)`
  padding-block-end: 20px;
`;

export const DevicesImageContainer = styled.div`
  margin-block-end: -24px;
  margin-inline: -20px;
  padding-inline: 20px;

  overflow: hidden;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: -64px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-end: -24px;
  }
`;

export const StyledImage = styled.img`
  position: relative;
  inset-inline-start: -5px;

  inline-size: calc(100% + 64px);
  margin-block: -20px -20px;
`;
