/**
 * @generated SignedSource<<d88afc422e82328f3b302b148937c370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksPickMorningStudioArticle$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"aiArticleListSponsoredArticle">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"topStoriesQueueItemsEdge">;
    }>;
  } | null | undefined;
  readonly " $fragmentType": "hooksPickMorningStudioArticle";
};
export type hooksPickMorningStudioArticle$key = {
  readonly " $data"?: hooksPickMorningStudioArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksPickMorningStudioArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksPickMorningStudioArticle"
};

(node as any).hash = "fcabecc02197860007e8f7976b84c1f7";

export default node;
