import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 140%;

  ${theme.breakpoints.up("tablet")} {
    font-size: 15px;
  }
`;

export const LiveTagContainer = styled.div`
  margin-block-end: 8px;

  :empty {
    margin-block-end: 0;
  }
`;

export const ActionBarContainer = styled.div`
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
`;
