/**
 * @generated SignedSource<<a876267be90915398616ddeaf58324d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topStoriesQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly entityId?: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
  readonly " $fragmentType": "topStoriesQueueItemsEdge";
}>;
export type topStoriesQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: topStoriesQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesQueueItemsEdge">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "topStoriesQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "topStoriesBlockQueueItemsEdge"
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "f4524f0e413d85a03a9a51722a9ed374";

export default node;
