import styled from "@emotion/styled";

import { BaseLink } from "shared/components/common/base-link";

export const CallToActionButton = styled.div`
  inline-size: fit-content;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  text-transform: uppercase;

  border: 1px solid #4585ff;
  border-radius: 2px;

  cursor: pointer;
`;

export const StyledBaseLink = styled(BaseLink)`
  inline-size: fit-content;

  color: #4585ff;
`;
