import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";
import { getAbsoluteUrl } from "shared/lib/utils";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useScmpWidgetConfig } from "scmp-app/lib/app/hooks";
import type { storyPackageQuery$key } from "scmp-app/queries/__generated__/storyPackageQuery.graphql";

import { useHomeStoryPackageTopic } from "./hooks";
import {
  Articles,
  Container,
  StyledContentItemHomeStoryPackageLead,
  StyledContentItemHomeStoryPackageTail,
  TopicBadge,
} from "./styles";

type Props = {
  reference: storyPackageQuery$key;
};

export const HomeStoryPackage: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment storyPackageQuery on Query
      @argumentDefinitions(storyPackageArticlesQueueName: { type: "String!" }) {
        storyPackageArticles: queue(filter: { name: $storyPackageArticlesQueueName }) {
          ...hooksHomeStoryPackageTopicQueue
          items(first: 3) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...entityLink
                  ...homeStoryPackageLeadContentItemContent
                  ...homeStoryPackageTailContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const { scmpWidgetConfigState } = useScmpWidgetConfig();
  const isReadingTimeEnabled =
    scmpWidgetConfigState?.result?.homepage_widgets_reading_time?.story_package === "1";

  const listOfArticles = (
    data?.storyPackageArticles?.items?.edges.map(edge => edge.node.entityId) ?? []
  ).join(",");

  const storyPackageTopic = useHomeStoryPackageTopic(data?.storyPackageArticles ?? undefined);

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        block_section: storyPackageTopic.name,
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "feature_package",
      },
      subcategory: "widget",
    }),
    [listOfArticles, storyPackageTopic],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const handleClick = (entityId?: string, destinationUrl?: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: entityId,
        block_section: storyPackageTopic.name,
        destination_url: getAbsoluteUrl(destinationUrl),
        page_type: "homepage",
        widget_name: "feature_package",
      },
      subcategory: "widget",
    });
  };

  const articleEdges = data?.storyPackageArticles?.items?.edges ?? [];

  const [leadArticleEdge, ...tailArticleEdges] = articleEdges;
  const leadArticle = leadArticleEdge?.node;

  if (!leadArticle) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.StoryPackage,
      }}
    >
      <Container ref={captureTrackImpressionEventTargetElement}>
        <TopicBadge>{storyPackageTopic.component}</TopicBadge>

        <Articles>
          <StyledContentItemHomeStoryPackageLead
            isReadingTimeEnabled={isReadingTimeEnabled}
            onClick={handleClick}
            reference={leadArticle}
          >
            {tailArticleEdges.map(({ node }, index) => (
              <StyledContentItemHomeStoryPackageTail
                isReadingTimeEnabled={isReadingTimeEnabled}
                key={index}
                onClick={(entityId, urlAlias) => handleClick(entityId, urlAlias)}
                reference={node}
              />
            ))}
          </StyledContentItemHomeStoryPackageLead>
        </Articles>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeStoryPackage.displayName = "HomeStoryPackage";
