import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { Edition } from "shared/lib/edition";
import { setFullViewportWidthStyles } from "shared/lib/styles";

import { AdSlotLabelHeight } from "scmp-app/components/advertisement/ad-slots/ad-slot/consts";
import { Container as AdSlotContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { Comment as CommentWidget } from "scmp-app/components/comment";
import { StyledContentItemHarry } from "scmp-app/components/comment/styles";

import { DesktopMaximumAdHeight } from "./consts";

export const Container = styled.div`
  inline-size: 100%;
  ${setFullViewportWidthStyles}
`;

export const ContentContainer = styled.div`
  max-inline-size: 1024px;
  margin-block-start: 20px;
  margin-inline: auto;
  padding-inline: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-inline: 32px;
  }
  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 1272px;
  }
`;

type ContentWrapperProps = {
  $edition: Edition;
  $isSecondSectionHigherThanMyNews?: boolean;
};

const getGridLayoutByEdition = (edition: Edition, isSecondSectionHigherThanMyNews?: boolean) => {
  switch (edition) {
    case Edition.HongKong:
      return css`
        grid:
          "hero-articles" min-content
          "first-ads" min-content
          "hero-three-articles" min-content
          "focus" min-content
          "top-stories" min-content
          "newsletters" min-content
          "plus" min-content
          "fourth-ads" min-content
          "comment" min-content
          "story-package-and-spotlight" min-content
          "latest" min-content
          "fifth-ads" min-content
          "recommended-for-you" min-content
          "first-section-block" min-content
          "subscription" min-content
          "things-to-do" min-content
          "sixth-ads" min-content
          "second-section-block" min-content
          "you-may-have-missed" min-content
          "multimedia" min-content
          "seventh-ads" min-content
          "third-section-block" min-content
          "my-news" min-content
          "fourth-section-block" min-content
          "fifth-section-block" min-content
          "most-popular" min-content
          "ninth-ads" min-content
          "from-our-advertisers" min-content
          "around-scmp" min-content
          / minmax(0, 1fr);

        ${theme.breakpoints.up("tablet")} {
          grid:
            "hero-articles" min-content
            "hero-three-articles" min-content
            "focus" min-content
            "first-ads" min-content
            "top-stories" min-content
            "plus" min-content
            "second-ads" min-content
            "comment" min-content
            "newsletters" min-content
            "story-package-and-spotlight" min-content
            "latest" min-content
            "fourth-ads" min-content
            "recommended-for-you" min-content
            "first-section-block" min-content
            "subscription" min-content
            "things-to-do" min-content
            "fifth-ads" min-content
            "second-section-block" min-content
            "you-may-have-missed" min-content
            "multimedia" min-content
            "sixth-ads" min-content
            "third-section-block" min-content
            "my-news" min-content
            "fourth-section-block" min-content
            "fifth-section-block" min-content
            "most-popular" min-content
            "seventh-ads" min-content
            "from-our-advertisers" min-content
            "around-scmp" min-content
            / minmax(0, 1fr);
        }

        ${theme.breakpoints.up("desktop")} {
          grid:
            "hero-articles ipu-newsletter" min-content
            "hero-articles ." 20px
            "hero-articles focus" min-content
            "hero-articles ." min-content
            ". ." 32px
            "hero-three-articles first-ads" min-content
            ". ." 32px
            "top-stories plus" min-content
            "top-stories ." 32px
            "top-stories second-ads" min-content
            "top-stories ." 32px
            "top-stories comment" min-content
            "top-stories ." 32px
            "top-stories third-ads" min-content
            "top-stories ." min-content
            "newsletters ." min-content
            ". ." 48px
            "story-package-and-spotlight latest" min-content
            "story-package-and-spotlight ." 32px
            "story-package-and-spotlight fourth-ads" min-content
            "story-package-and-spotlight ." 32px
            "story-package-and-spotlight ." min-content
            ". ." 48px
            "recommended-for-you things-to-do" min-content
            "first-section-block things-to-do" min-content
            "first-section-block ." 32px
            "first-section-block fifth-ads" min-content
            "first-section-block ." min-content
            ". ." 48px
            "subscription ." min-content
            ". ." 48px
            /** This is a workaround for handling different height on my-news widget
             *  the reason of only need this in HK edition is because second-section-block height is not always longer than my-news
            */
            ${isSecondSectionHigherThanMyNews
              ? `
                  "second-section-block multimedia" min-content
                  "second-section-block multimedia" min-content
                  "you-may-have-missed multimedia" min-content
                  "you-may-have-missed ." 32px
                  "you-may-have-missed sixth-ads" min-content
                  "you-may-have-missed ." min-content
                `
              : `
                  "second-section-block multimedia" min-content
                  "you-may-have-missed multimedia" min-content
                  "you-may-have-missed ." 32px
                  "you-may-have-missed sixth-ads" min-content
                  "you-may-have-missed ." min-content
                `}
            ". ." 48px
            "third-section-block most-popular" min-content
            "third-section-block ." 32px
            "third-section-block seventh-ads" min-content
            "fourth-section-block seventh-ads" min-content
            "fourth-section-block ." min-content
            ". ." 48px
            "fifth-section-block eighth-ads" min-content
            "fifth-section-block ." 32px
            "fifth-section-block ." min-content
            "fifth-section-block my-news" min-content
            ". ." 48px
            "from-our-advertisers from-our-advertisers" min-content
            ". ." 48px
            "around-scmp around-scmp" min-content
            / minmax(0, 1fr) minmax(0, 300px);

          ${YouMayHaveMissed} {
            margin-block-start: 48px;
          }
        }
      `;
    default:
      return css`
        grid:
          "hero-articles" min-content
          "first-ads" min-content
          "hero-three-articles" min-content
          "focus" min-content
          "top-stories" min-content
          "newsletters" min-content
          "plus" min-content
          "fourth-ads" min-content
          "comment" min-content
          "story-package-and-spotlight" min-content
          "latest" min-content
          "fifth-ads" min-content
          "recommended-for-you" min-content
          "first-section-block" min-content
          "subscription" min-content
          "multimedia" min-content
          "sixth-ads" min-content
          "second-section-block" min-content
          "you-may-have-missed" min-content
          "my-news" min-content
          "seventh-ads" min-content
          "third-section-block" min-content
          "fourth-section-block" min-content
          "fifth-section-block" min-content
          "most-popular" min-content
          "ninth-ads" min-content
          "from-our-advertisers" min-content
          "around-scmp" min-content
          / minmax(0, 1fr);

        ${theme.breakpoints.up("tablet")} {
          grid:
            "hero-articles" min-content
            "hero-three-articles" min-content
            "focus" min-content
            "first-ads" min-content
            "top-stories" min-content
            "newsletters" min-content
            "plus" min-content
            "second-ads" min-content
            "comment" min-content
            "story-package-and-spotlight" min-content
            "latest" min-content
            "fourth-ads" min-content
            "recommended-for-you" min-content
            "first-section-block" min-content
            "subscription" min-content
            "multimedia" min-content
            "fifth-ads" min-content
            "second-section-block" min-content
            "you-may-have-missed" min-content
            "my-news" min-content
            "sixth-ads" min-content
            "third-section-block" min-content
            "fourth-section-block" min-content
            "fifth-section-block" min-content
            "most-popular" min-content
            "seventh-ads" min-content
            "from-our-advertisers" min-content
            "around-scmp" min-content
            / minmax(0, 1fr);
        }

        ${theme.breakpoints.up("desktop")} {
          grid:
            "hero-articles ipu-newsletter" min-content
            "hero-articles ." 20px
            "hero-articles focus" min-content
            "hero-articles ." min-content
            ". ." 32px
            "hero-three-articles first-ads" min-content
            ". ." 32px
            "top-stories plus" min-content
            "top-stories ." 32px
            "top-stories second-ads" min-content
            "top-stories ." 32px
            "top-stories comment" min-content
            "top-stories ." 32px
            "top-stories third-ads" min-content
            "top-stories ." min-content
            "newsletters ." min-content
            ". ." 48px
            "story-package-and-spotlight latest" min-content
            "story-package-and-spotlight ." 32px
            "story-package-and-spotlight fourth-ads" min-content
            "story-package-and-spotlight ." min-content
            ". ." 48px
            "recommended-for-you multimedia" min-content
            "first-section-block multimedia" min-content
            "first-section-block ." 32px
            "first-section-block fifth-ads" min-content
            "first-section-block ." min-content
            ". ." 48px
            "subscription ." min-content
            ". ." 48px
            "second-section-block my-news" min-content
            "you-may-have-missed my-news" min-content
            "you-may-have-missed ." 32px
            "you-may-have-missed sixth-ads" min-content
            "you-may-have-missed ." min-content
            ". ." 48px
            "third-section-block most-popular" min-content
            "third-section-block ." 32px
            "third-section-block seventh-ads" min-content
            "fourth-section-block seventh-ads" min-content
            "fourth-section-block ." min-content
            ". ." 48px
            "fifth-section-block eighth-ads" min-content
            ". ." 48px
            "from-our-advertisers from-our-advertisers" min-content
            ". ." 48px
            "around-scmp around-scmp" min-content
            / minmax(0, 1fr) minmax(0, 300px);
        }
      `;
  }
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  row-gap: 32px;

  time {
    line-height: 14px;
  }

  ${props => getGridLayoutByEdition(props.$edition, props.$isSecondSectionHigherThanMyNews)}

  ${theme.breakpoints.up("desktop")} {
    gap: 0 40px;
  }
`;

export const HeroArticles = styled.div`
  grid-area: hero-articles;
`;

export const IpuNewsletter = styled.div`
  grid-area: ipu-newsletter;

  display: none;
  ${theme.breakpoints.up("desktop")} {
    display: block;
  }
`;

export const Focus = styled.div`
  grid-area: focus;

  padding-block-start: 12px;
  border-block-start: 1px solid rgba(0, 0, 0);
`;
export const HeroThreeArticles = styled.div`
  grid-area: hero-three-articles;

  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.2);

  ${theme.breakpoints.up("tablet")} {
    padding-block-start: 32px;
  }
`;

export const TopStories = styled.div`
  grid-area: top-stories;

  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.2);

  ${theme.breakpoints.up("tablet")} {
    padding-block-start: 32px;
  }
`;

export const Latest = styled.div`
  grid-area: latest;
`;

type ThingsToDoProps = {
  $edition: Edition;
};
export const ThingsToDo = styled.div<ThingsToDoProps>`
  grid-area: things-to-do;
  ${props =>
    props.$edition !== Edition.HongKong &&
    css`
      display: none;
    `}
`;

export const Newsletters = styled.div`
  grid-area: newsletters;

  ${theme.breakpoints.up("desktop")} {
    padding-block-start: 48px;
  }
`;
export const SectionBlocksAndSpotlight = styled.div`
  grid-area: story-package-and-spotlight;

  padding-block-start: 16px;
  border-block-start: 1px solid #000000;
`;
export const Comment = styled.div`
  grid-area: comment;
`;

export const StyledCommentWidget = styled(CommentWidget)`
  ${StyledContentItemHarry} {
    justify-content: space-between;
  }
`;

export const RecommendedForYou = styled.div`
  grid-area: recommended-for-you;
`;

type MyNewsProps = {
  $edition?: Edition;
};
export const MyNews = styled.div<MyNewsProps>`
  grid-area: my-news;

  ${theme.breakpoints.up("desktop")} {
    ${props =>
      props.$edition === Edition.HongKong &&
      css`
        display: none;
      `}
  }
`;
export const MostPopular = styled.div`
  grid-area: most-popular;
`;
export const Multimedia = styled.div`
  grid-area: multimedia;
`;

export const Subscription = styled.div`
  grid-area: subscription;
`;
export const YouMayHaveMissed = styled.div`
  grid-area: you-may-have-missed;
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 48px;
  }
`;

export const FromOurAdvertisers = styled.div`
  grid-area: from-our-advertisers;
`;
export const AroundScmp = styled.div`
  grid-area: around-scmp;
`;

export const AdContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  background: #f7f7f7;

  overflow-x: hidden;
`;

export const FirstSectionBlock = styled.div`
  grid-area: first-section-block;
  ${theme.breakpoints.up("desktop")} {
    padding-block-start: 32px;
  }
`;

export const SecondSectionBlock = styled.div`
  grid-area: second-section-block;
`;

export const ThirdSectionBlock = styled.div`
  grid-area: third-section-block;
`;

export const FourthSectionBlock = styled.div`
  grid-area: fourth-section-block;
  ${theme.breakpoints.up("desktop")} {
    padding-block-start: 32px;
  }
`;

export const FifthSectionBlock = styled.div`
  grid-area: fifth-section-block;
`;
export const SixthSectionBlock = styled.div`
  grid-area: sixth-section-block;
`;

export const FirstAds = styled.div`
  grid-area: first-ads;
`;
export const SecondAds = styled.div`
  grid-area: second-ads;

  ${AdSlotContainer} {
    background: #f7f7f7;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: ${DesktopMaximumAdHeight + AdSlotLabelHeight}px;
  }
`;
export const ThirdAds = styled.div`
  grid-area: third-ads;

  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: block;

    ${AdSlotContainer} {
      block-size: auto;
      max-block-size: ${DesktopMaximumAdHeight + AdSlotLabelHeight}px;

      background: #f7f7f7;
    }
  }
`;
export const FourthAds = styled.div`
  grid-area: fourth-ads;
`;
export const FifthAds = styled.div`
  grid-area: fifth-ads;
`;
export const SixthAds = styled.div`
  grid-area: sixth-ads;
  ${AdSlotContainer} {
    background: #f7f7f7;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: auto;
    max-block-size: ${DesktopMaximumAdHeight + AdSlotLabelHeight}px;
  }
`;
export const SeventhAds = styled.div`
  grid-area: seventh-ads;
  ${AdSlotContainer} {
    background: #f7f7f7;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: auto;
  }
`;
export const EighthAds = styled.div`
  grid-area: eighth-ads;

  display: none;
  ${theme.breakpoints.up("desktop")} {
    display: block;
  }
`;
export const NinthAds = styled.div`
  grid-area: ninth-ads;

  display: block;
  ${theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

export const Plus = styled.div`
  grid-area: plus;
`;
