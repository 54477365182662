/**
 * @generated SignedSource<<35522a769f2d7d45d025be8f6fb6326d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type heroArticleArticle$data = {
  readonly entityId: string;
  readonly moreOnThisArticles: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"homeHeroArticleContentItemContent">;
  readonly " $fragmentType": "heroArticleArticle";
};
export type heroArticleArticle$key = {
  readonly " $data"?: heroArticleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"heroArticleArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "heroArticleArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeHeroArticleContentItemContent"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "moreOnThisArticles",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "83bf2ec75bf0d547ed35b50dfde19952";

export default node;
