import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeAroundSecondaryContentItemContent$key } from "scmp-app/queries/__generated__/homeAroundSecondaryContentItemContent.graphql";

import { Container, Content, Headline, StyledEntityLink } from "./styles";
export type Props = ContentItemRenderVariantProps & {
  reference: homeAroundSecondaryContentItemContent$key;
};

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeAroundSecondaryContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent @arguments(withHeadline: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <Content>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHeadlineSponsorStyle: true,
              })}
            </Headline>
          </Content>
        </StyledEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeAroundSecondary";

export const ContentItemHomeAroundSecondary = withHighlightedHeadline(Component);
