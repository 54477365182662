/**
 * @generated SignedSource<<e6b99df4ae83d8721f0be04dd1647807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeDailyFiveContentItemContent$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeDailyFiveContentItemContent";
};
export type homeDailyFiveContentItemContent$key = {
  readonly " $data"?: homeDailyFiveContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeDailyFiveContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeDailyFiveContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "a3c092c29eeec2bb662531f1a2289152";

export default node;
